import React, { Component, Suspense } from 'react';
import {Switch, Route, Redirect, BrowserRouter as Router } from 'react-router-dom';
import Loadable from 'react-loadable';

import '../../node_modules/font-awesome/scss/font-awesome.scss';

import Loader from './layout/Loader'
import Aux from "../hoc/_Aux";
import { connect } from "react-redux";

const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout/index'),
    loading: Loader
});

class App extends Component {
    render() {
        const SignIn = React.lazy(() => import('../views/Authentication/SignIn.js'));
        const ForgetPassword = React.lazy(() => import('../views/Authentication/ForgetPassword'));
        const ResetPassword = React.lazy(() => import('../views/Authentication/ResetPassword'));
        const UploadReceipt = React.lazy(() => import('../views/UploadReceipt'));
        const NotFound = ()=>
            (
                <div>
                  <h1>404 - Not Found!</h1>
                 
                </div>
              )
        
        return (
            <Router>
                <Suspense fallback={<Loader />}>
                    <Switch>         
                        <Route exact path="/" component={SignIn} />
                        <Route path="/login" component={SignIn} />
                        <Route exact path="/forgetPassword" component={ForgetPassword} />
                        <Route exact path="/resetPassword/:reference_code" component={ResetPassword} />
                        <Route exact path="/applicationPayment/:reference_code" component={UploadReceipt} />
                        <Route path="/" component={AdminLayout} />
                    </Switch>
                </Suspense>
            </Router>
            // <Aux>
            //     {/* <ScrollToTop> */}
            //         <Router>
            //             <Suspense fallback={<Loader />}>
            //                 <Switch>
            //                     {
            //                         this.props.token ?
            //                             < Route path="/" component={AdminLayout} /> :
            //                             <>
            //                             <Route path="/login" component={SignIn} />
            //                                 <Route exact path="/forgetPassword" component={ForgetPassword} />
            //                                 <Route exact path="/resetPassword/:reference_code" component={ResetPassword} />
            //                                 <Route exact path="/applicationPayment/:reference_code" component={UploadReceipt} />
            //                                 <Route exact path="/" component={SignIn} />
            //                             </>
            //                     }
            //                 </Switch>
            //             </Suspense>
            //         </Router>
            //     {/* </ScrollToTop> */}
            // </Aux>
        );
    }
}

function mapStateToProps(state) {
    return {
        token: state.auth
    }
}

export default connect(mapStateToProps)(App);
